<script>
  import { onMount } from "svelte";

  onMount(() => {
    resetTimer();
  });

  let timer;
  let startTime;
  let updatedTime;
  let difference;
  let tInterval;
  let savedTime;
  let paused = 0;
  let running = 0;
  let zerado;

  let hours;
  let minutes;
  let seconds;
  let milliseconds;

  function startTimer() {
    if (!running) {
      startTime = new Date().getTime();
      tInterval = setInterval(getShowTime, 1);
      paused = 0;
      running = 1;
    }
  }

  function pauseTimer() {
    if (!difference) {
      // if timer never started, don't allow pause button to do anything
    } else if (!paused) {
      clearInterval(tInterval);
      savedTime = difference;
      paused = 1;
      running = 0;
    } else {
      startTimer();
    }
  }

  function resetTimer() {
    zerado = true;
    clearInterval(tInterval);
    savedTime = 0;
    difference = 0;
    paused = 0;
    running = 0;
    hours = "00";
    minutes = "00";
    seconds = "00";
    milliseconds = "000";
  }

  function getShowTime() {
    zerado = false;

    updatedTime = new Date().getTime();
    if (savedTime) {
      difference = updatedTime - startTime + savedTime;
    } else {
      difference = updatedTime - startTime;
    }
    // var days = Math.floor(difference / (1000 * 60 * 60 * 24));
    hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((difference % (1000 * 60)) / 1000);
    milliseconds = Math.floor((difference % (1000 * 60)) / 100);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    milliseconds =
      milliseconds
        .toString()
        .split("")
        .pop() + "00";
  }
</script>

<style>
  .wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 80% 20%;
    grid-gap: 0;
    height: 100%;
    justify-items: center;
    align-items: center;
  }

  .cell {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .timer {
    font-size: 60px;
    grid-column-start: 1;
    grid-column-end: 3;
    color: rgba(255, 255, 255, 0.9);
    /* border-bottom: 2px solid white; */
  }

  @media screen and (min-width: 600px) {
    .timer {
      font-size: 100px;
    }
  }

  @media screen and (min-width: 800px) {
    .timer {
      font-size: 120px;
    }
  }

  @media screen and (min-width: 1000px) {
    .timer {
      font-size: 200px;
    }
  }

  .zerado .timer {
    color: rgba(255, 255, 255, 0.7);
  }

  .btn {
    background: rgba(0, 0, 0, 0.4);
    color: rgba(255, 255, 255, 0.9);
  }

  .btn:hover {
    background: rgba(0, 0, 0, 0.45);
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }

  .btn:active {
    background: rgba(0, 0, 0, 0.5);
  }

  .zerado .btn {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .label {
    text-align: center;
  }

  .label span {
    display: block;
  }

  .label span {
    font-size: 20px;
  }

  img {
    height:35px;
  }
</style>

<div class="wrapper {zerado ? 'zerado' : ''}">
  <div class="cell timer">
    <strong>{minutes}</strong>
    :
    <strong>{seconds}</strong>
    .
    <small>{milliseconds}</small>
  </div>
  {#if running}
    <div class="cell btn pause" on:click={pauseTimer}>
      <div class="label">
        <!-- <i class="fas fa-pause" /> -->
        <img src="pause.svg" alt="pause" />
        <span>Parar</span>
      </div>
    </div>
  {:else}
    <div class="cell btn play" on:click={startTimer}>
      <div class="label">
        <!-- <i class="fas fa-play" /> -->
        <img src="play.svg" alt="play" />
        <span>{zerado ? 'Iniciar' : 'Continuar'}</span>
      </div>
    </div>
  {/if}

  {#if !zerado}
    <div class="cell btn reset" on:click={resetTimer}>
      <div class="label">
        <!-- <i class="fas fa-sync-alt" /> -->
        <img src="reset.svg" alt="reset" />
        <span>Zerar</span>
      </div>
    </div>
  {/if}
</div>
